/*******************************************************
        My Custom Stylesheet FRONT
*******************************************************/

label.required:after {
    content: " *";
}

.color_blue{
    color: #00547a;
}

.color_orange{
    color: #fd4d35;
}

.font_archi{
    font-family: 'Architects Daughter', serif;
}

.span_to_p{
    font-size: 16px;
}

/*******************************************************
        My Custom FRONT
*******************************************************/

.jqvmap-zoomin, .jqvmap-zoomout {
    padding: 5px !important;
    width: 25px !important;
    height: 25px !important;
}
.jqvmap-zoomout{
    top: 50px !important;
}

#vmap{
    width: 240px;
    height: 200px;
    margin-left: auto;
    margin-right: auto;
}

@media (min-width: 370px) {
    #vmap{
        width: 300px;
        height: 225px;
    }
}

@media (min-width: 420px) {
    #vmap{
        width: 350px;
        height: 250px;
    }
}

@media (min-width: 500px) {
    #vmap{
        width: 400px;
        height: 300px;
    }
}

/* .col-sm- Small devices (tablets, 768px and up) */
@media (min-width: 540px) {
    #vmap{
        width: 450px;
        height: 300px;
    }
}

/* .col-md- Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
    #vmap{
        width: 400px;
        height: 300px;
    }
}

/* .col-lg- Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
    #vmap{
        width: 600px;
        height: 450px;
    }
}

/* .col-xl- Extra Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
    #vmap{
        width: 700px;
        height: 500px;
    }
}

/* HEADER */
#search{
    font-family: 'Salsa', serif !important;
    font-size: 22px !important;
}

.menu_title > a{
    font-family: 'Salsa', serif !important;
    font-size: 17px !important;
}

.menu_title > a > div{
    padding-top: 1px;
    padding-bottom: 1px;
}

#primary-menu.style-3 > ul > li > a {
    color: white;
}

#primary-menu.style-3 > ul > li.current > a {
    background-color: #fd4d35;
    color: white !important;
}

#primary-menu.style-3 > ul > li.current > a:hover {
    color: #00547a !important;
}

#primary-menu.style-3 > #top-search > form > input::placeholder {
    font-size: 14px;
}

@media (min-width: 992px) {
    #primary-menu.style-3 > #top-search > a > i {
        color: white;
    }

    #header.sticky-header:not(.static-sticky) #primary-menu.style-3 > #top-search > a > i {
        color: #444;
    }

    #primary-menu.style-3 > #top-search > form > input {
        color: white;
    }

    #header.sticky-header:not(.static-sticky) #primary-menu.style-3 > #top-search > form > input {
        color: #444;
    }

    #primary-menu.style-3 > #top-search > form > input::placeholder {
        color: white;
        font-size: 18px;
    }

    #header.sticky-header:not(.static-sticky) #primary-menu.style-3 > #top-search > form > input::placeholder {
        color: #444;
        font-size: 18px;
    }
}


#header.sticky-header:not(.static-sticky) #primary-menu > ul > li > a {
    padding-top: 18px;
    padding-bottom: 18px;
}

#header.sticky-header:not(.static-sticky) #primary-menu.style-3 > ul > li.current > a {
    color: white !important;
}

#header.sticky-header:not(.static-sticky) #primary-menu.style-3 > ul > li > a {
    color: black !important;
}

#primary-menu.style-3 > ul > li > a {
    color: #444;
    background-color: #F5F5F5;
}

#primary-menu.style-3 > ul > li:hover > a {
    color: #fd4d35;
}

#primary-menu.style-3 > ul > li > a:hover {
    color: #fd4d35 !important;
}

.current > a {
    color: #fd4d35 !important;
}

.current:hover > a {
    color: #fd4d35 !important;
}

@media (max-width: 991px){
    body:not(.dark) #header:not(.dark) #header-wrap:not(.dark) #primary-menu > ul > li.current a,
    body:not(.dark) #header:not(.dark) #header-wrap:not(.dark) #primary-menu > div > ul > li.current a {
        color: white !important;
        background-color: #fd4d35 !important;
    }
}

@media (max-width: 991px){
    body:not(.dark) #header:not(.dark) #header-wrap:not(.dark) #primary-menu > ul > li:hover a,
    body:not(.dark) #header:not(.dark) #header-wrap:not(.dark) #primary-menu > div > ul > li:hover a {
        color: #00547a !important;
    }
}

@media (max-width: 991px) {
    #primary-menu ul li.current > a {
        color: #fd4d35 !important;
    }

    .current:hover > a {
        color: #fd4d35 !important;
    }
}

.submenu_title{
    font-family: 'Merienda', serif;
    font-size: 14px;
}

.submenu_title:hover > a{
    color: #00547a !important;
}

/* LANDING PAGE */
#landing_title_a{
    color: white !important;
}

#landing_title_a:hover{
    color: white;
}

/* ROAD PAGE */
.timeline-border{
    background-color: #00547a;
}

.entry > .entry-timeline{
    border-color: #00547a !important;
    color: #00547a !important;
}

.entry > .entry-timeline > .timeline-divider{
    border-color: #00547a !important;
    color: #00547a !important;
}

.entry:hover > .entry-timeline{
    border-color: #fd4d35 !important;
    color: #fd4d35 !important;
}

.entry:hover > .entry-timeline > .timeline-divider{
    border-color: #fd4d35 !important;
    color: #fd4d35 !important;
}

.more-link{
    border-bottom: 1px solid #00547a;
}

.more-link:hover{
    border-bottom: 1px solid #fd4d35;
}

.page-link{
    color: #00547a;
}

.page-link:focus{
    color: #FFF !important;
    background-color: #fd4d35 !important;
    border-color: #fd4d35 !important;
}

.page-link:hover{
    color: #FFF !important;
    background-color: #fd4d35 !important;
    border-color: #fd4d35 !important;
}

.page-item.active .page-link{
    color: #FFF !important;
    background-color: #00547a !important;
    border-color: #00547a !important;
}

/* CONTACT PAGE */
.alert{
    font-family: 'Merienda', serif;
}

.form-control{
    font-family: 'Architects Daughter', serif;
}

#contact_submit:hover{
    background-color: #00547a !important;
}

/* SEARCH PAGE */
.search_li_filter{
    font-family: 'Merienda', serif;
}

.search_li_filter:hover{
    color: #00547a !important;
}

/* ABOUT PAGE */
.about_span{
    color: #00547a !important;
}

/* ALL PAGES */
h1{
    font-family: 'Merienda', serif;
    font-size: 26px;
}

h2{
    font-family: 'Merienda', serif;
    font-size: 20px;
    margin: 15px 0 10px 0;
}

h2 > a{
    font-family: 'Merienda', serif;
    font-size: 20px;
    text-transform: none;
    color: #333;
}

h2 > a:hover{
    color: #333 !important;
    border-top: 1px solid #00547a;
    border-bottom: 1px solid #00547a;
}

h3{
    margin: 15px 0 10px 0;
    font-family: 'Merienda', serif;
    font-size: 18px;
}

h3 > a:hover{
    color: #00547a !important;
}

h4{
    font-family: 'Merienda', serif;
    font-size: 16px;
}

p{
    font-family: 'Architects Daughter', serif;
    font-size: 16px;
}

a{
    color: black;
    cursor: pointer;
}

a:hover{
    color: #00547a !important;
}

.ckeditor_div > p{
    margin-bottom: 15px;
    font-family: 'Architects Daughter', serif;
    font-size: 16px;
}

.ckeditor_div > ul{
    margin-left: 15px;
    margin-bottom: 15px;
    font-family: 'Architects Daughter', serif;
    font-size: 16px;
}

.ckeditor_div > ol{
    margin-left: 20px;
    margin-bottom: 15px;
    font-family: 'Architects Daughter', serif;
    font-size: 16px;
}

.title_footer{
    font-family: 'Merienda', serif;
    font-size: 14px;
    margin-bottom: 10px;
}

.title_footer > a{
    color: rgba(255,255,255,0.25);
}

.title_footer > a:hover{
    color: #EEE !important;
    cursor: pointer;
}

/*******************************************************
        My Custom FONT
*******************************************************/

@font-face {
    font-family: "Salsa";
    src: url("../../fonts/private/Salsa-Regular.ttf");
}

@font-face {
    font-family: "Merienda";
    src: url("../../fonts/private/Merienda-Regular.ttf");
}

@font-face {
    font-family: "Architects Daughter";
    src: url("../../fonts/private/ArchitectsDaughter-Regular.ttf");
}

@font-face {
    font-family: "Kurale";
    src: url("../../fonts/private/Kurale-Regular.ttf");
}

.ff_salsa{
    font-family: 'Salsa', serif;
}

.ff_merienda{
    font-family: 'Merienda', serif;
}

.ff_architectsdaughter{
    font-family: 'Architects Daughter', serif;
}

.ff_kurale{
    font-family: 'Kurale', serif;
}

.ff_indeflower{
    font-family: 'Indie Flower', serif;
}

/*******************************************************
        My Custom @MEDIA
https://getbootstrap.com/docs/4.0/layout/grid/#grid-options
*******************************************************/

@media screen and (min-width: 1920px) {
    #page-title{
        background-size: cover;
    }
    .page-title-responsive{
        background-size: contain;
    }
    .page-title-responsive_alt{
        background-size: contain;
    }
}

/**
Extra large - .col-xl-
≥1200px
 */
@media screen and (min-width: 1140px) {
    .img_width{
        width: 70% !important;
    }
    .current > a {
        color: #fd4d35 !important;
    }

    .current:hover > a {
        color: #fd4d35 !important;
    }
    .page-title-responsive{
        background-size: contain;
    }
    .page-title-responsive_alt{
        background-size: cover;
    }
}

/**
Large - .col-lg-
≥992px
 */
@media screen and (min-width: 960px) {
    .img_width{
        width: 70% !important;
    }
    #primary-menu.style-3 > ul > li.current > a {
        background-color: #fd4d35 !important;
        color: white !important;
    }
    .page-title-responsive{
        background-size: contain;
    }
    .page-title-responsive_alt{
        background-size: cover;
    }
}

/**
Medium - .col-md-
≥768px
 */
@media screen and (min-width: 720px) {
    .img_width{
        width: 70% !important;
    }
    #primary-menu.style-3 > ul > li > a {
        background-color: white !important;
    }
}

/**
Small - .col-sm-
≥576px
 */
@media screen and (min-width: 540px) {
    .img_width{
        width: 70% !important;
    }
    #primary-menu.style-3 > ul > li > a {
        background-color: white !important;
    }
}

/**
Extra small - .col-
<576px
 */
@media screen and (max-width: 540px) {
    .img_width{
        width: 70% !important;
    }
    #primary-menu.style-3 > ul > li > a {
        background-color: white !important;
    }

    .flexslider_custom{
        height: 800px !important;
    }
}

/*******************************************************
        My Custom Stylesheet ID
*******************************************************/
#landing_title_voyage{
    font-size: 150px;
    font-family: 'Kurale', serif;
}

#defaultCountdown{
    max-width: 700px;
    font-family: 'Kurale', serif;
}

.dark .countdown-section {
    color: white;
}

.dark .countdown-amount, .dark .rounded-skill {
    color: white;
}

@media screen and (max-width: 992px) {
    #footer_leftpart{
        margin-top: 10px;
    }
}

@media screen and (max-width: 770px) {
    #landing_title_voyage{
        font-size: 100px;
    }
}

@media screen and (max-width: 500px) {
    #landing_title_voyage{
        font-size: 50px;
    }
}

/*******************************************************
        Nestable plugin
*******************************************************/


/* Nestable list */
.dd {
    position: relative;
    display: block;
    margin: 0;
    padding: 0;
    list-style: none;
    font-size: 13px;
    line-height: 20px;
}
.dd-list {
    display: block;
    position: relative;
    margin: 0;
    padding: 0;
    list-style: none;
}
.dd-list .dd-list {
    padding-left: 30px;
}
.dd-collapsed .dd-list {
    display: none;
}
.dd-item,
.dd-empty,
.dd-placeholder {
    display: block;
    position: relative;
    margin: 0;
    padding: 0;
    min-height: 20px;
    font-size: 13px;
    line-height: 20px;
}
.dd-handle {
    display: block;
    margin: 5px 0;
    padding: 5px 10px;
    color: #333;
    text-decoration: none;
    border: 1px solid #e7eaec;
    background: #f5f5f5;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
}
.dd-handle span {
    font-weight: bold;
}
.dd-handle:hover {
    background: #f0f0f0;
    cursor: pointer;
    font-weight: bold;
}
.dd-item > button {
    display: block;
    position: relative;
    cursor: pointer;
    float: left;
    width: 25px;
    height: 20px;
    margin: 5px 0;
    padding: 0;
    text-indent: 100%;
    white-space: nowrap;
    overflow: hidden;
    border: 0;
    background: transparent;
    font-size: 12px;
    line-height: 1;
    text-align: center;
    font-weight: bold;
}
.dd-item > button:before {
    content: '+';
    display: block;
    position: absolute;
    width: 100%;
    text-align: center;
    text-indent: 0;
}
.dd-item > button[data-action="collapse"]:before {
    content: '-';
}
#nestable2 .dd-item > button {
    font-family: FontAwesome;
    height: 34px;
    width: 33px;
    color: #c1c1c1;
}
#nestable2 .dd-item > button:before {
    content: "\f067";
}
#nestable2 .dd-item > button[data-action="collapse"]:before {
    content: "\f068";
}
.dd-placeholder,
.dd-empty {
    margin: 5px 0;
    padding: 0;
    min-height: 30px;
    background: #f2fbff;
    border: 1px dashed #b6bcbf;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
}
.dd-empty {
    border: 1px dashed #bbb;
    min-height: 100px;
    background-color: #e5e5e5;
    background-image: -webkit-linear-gradient(45deg, #ffffff 25%, transparent 25%, transparent 75%, #ffffff 75%, #ffffff), -webkit-linear-gradient(45deg, #ffffff 25%, transparent 25%, transparent 75%, #ffffff 75%, #ffffff);
    background-image: -moz-linear-gradient(45deg, #ffffff 25%, transparent 25%, transparent 75%, #ffffff 75%, #ffffff), -moz-linear-gradient(45deg, #ffffff 25%, transparent 25%, transparent 75%, #ffffff 75%, #ffffff);
    background-image: linear-gradient(45deg, #ffffff 25%, transparent 25%, transparent 75%, #ffffff 75%, #ffffff), linear-gradient(45deg, #ffffff 25%, transparent 25%, transparent 75%, #ffffff 75%, #ffffff);
    background-size: 60px 60px;
    background-position: 0 0, 30px 30px;
}
.dd-dragel {
    position: absolute;
    z-index: 9999;
    pointer-events: none;
}
.dd-dragel > .dd-item .dd-handle {
    margin-top: 0;
}
.dd-dragel .dd-handle {
    -webkit-box-shadow: 2px 4px 6px 0 rgba(0, 0, 0, 0.1);
    box-shadow: 2px 4px 6px 0 rgba(0, 0, 0, 0.1);
}
/**
* Nestable Extras
*/
.nestable-lists {
    display: block;
    clear: both;
    padding: 30px 0;
    width: 100%;
    border: 0;
    border-top: 2px solid #ddd;
    border-bottom: 2px solid #ddd;
}
#nestable-menu {
    padding: 0;
    margin: 10px 0 20px 0;
}
#nestable-output,
#nestable2-output {
    width: 100%;
    font-size: 0.75em;
    line-height: 1.333333em;
    font-family: open sans, lucida grande, lucida sans unicode, helvetica, arial, sans-serif;
    padding: 5px;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
}
#nestable2 .dd-handle {
    color: inherit;
    border: 1px dashed #e7eaec;
    background: #f3f3f4;
    padding: 10px;
}
#nestable2 span.label {
    margin-right: 10px;
}

#nestable-output,
#nestable2-output {
    font-size: 12px;
    padding: 25px;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
}